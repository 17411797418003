<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard', params: { realm: this.$route.params.realmId } }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link>
                </p>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ name }}</h1>
                <p class="text-caption text-center">Account overview</p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10" v-if="account">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card class="mt-8">
                        <v-app-bar color="teal" dark flat dense>
                            <v-app-bar-title>Account overview</v-app-bar-title>
                            <v-spacer/>
                            <v-menu offset-y left open-on-click open-on-hover close-delay="100">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on">
                                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </template>
                                <v-list class="ma-0 pa-0">
                                    <v-list-item-group>
                                    <v-list-item :to="{ name: 'account-settings', params: { accountId: this.$route.params.accountId } }">
                                        <v-list-item-icon>
                                            <font-awesome-icon :icon="['fas', 'cog']" fixed-width/>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Settings</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-menu>
                        </v-app-bar>
                        <v-card-text>
                            <p class="text-overline mb-0 mt-0">Brands</p>
                            <p class="text-caption mb-0 mt-0">A brand is a distinctive name or design used to identify a product, service, or organization.</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-search-brand', params: { accountId: this.$route.params.accountId } }">See all brands</router-link>
                            </p>

                            <p class="text-overline mb-0 mt-8">Domains</p>
                            <p class="text-caption mb-0 mt-0">List of domains used for websites, email, etc. A domain can be used by multiple brands.</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-search-domain', params: { accountId: this.$route.params.accountId } }">See all domains</router-link>
                            </p>

                            <!-- <p class="text-overline mb-0 mt-8">Themes</p>
                            <p class="text-caption mb-0 mt-0">A theme is a coordinated set of color palettes, images, and text.</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-search-theme', params: { accountId: this.$route.params.accountId }, query: { brandId: this.$route.params.brandId } }">See all themes</router-link>
                            </p> -->

                            <!-- TODO: logo, screenshots, other images -->
                            <!-- <p class="text-overline mb-0 mt-8">Images</p>
                            <p class="text-caption mb-0 mt-0">Logos, screenshots, etc.</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-search-image', params: { accountId: this.$route.params.accountId }, query: { brandId: this.$route.params.brandId } }">See all images</router-link>
                            </p> -->

                            <!-- <p class="text-overline mb-0 mt-8">Colors and Palettes</p>
                            <p class="text-caption mb-0 mt-0">Color definitions and combinations</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-search-color', params: { accountId: this.$route.params.accountId }, query: { brandId: this.$route.params.brandId } }">See all colors</router-link>
                            </p>
                            <p>
                                <router-link :to="{ name: 'account-search-palette', params: { accountId: this.$route.params.accountId }, query: { brandId: this.$route.params.brandId } }">See all palettes</router-link>
                            </p> -->

                            <!-- TODO: tags -->
                            <!-- <p class="text-overline mb-0 mt-8">Tags</p>
                            <p class="text-caption mb-0 mt-0">Used to find related assets in your BrandProfile.</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-search-tag', params: { accountId: this.$route.params.accountId } }">See all tags</router-link>
                            </p> -->
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
// import RealmList from '@/components/account-dashboard/RealmList.vue';

export default {
    components: {
        // RealmList,
    },
    data: () => ({
        accountSummary: null,
        error: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            account: (state) => state.account,
        }),
    },
    methods: {
        async loadAccountSummary() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccountSummary: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.check({ item: 'summary' });
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.accountSummary = response; // {"domain_count":2,"domain_verification_count":0,"dynamic_dns_count":0,"user_count":1,"domain_dispute_defendant":0,"domain_dispute_claimant":0,"dns_record_count":4}
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account report', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccountSummary: false });
            }
        },
    },
    mounted() {
        this.loadAccountSummary();
    },
};
</script>
